import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Paper,
  MenuItem,
  Divider,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Grid,
  IconButton,
  Popover,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withTranslation } from "react-i18next";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import AlertError from "../../../components/src/AlertError.web";
import Loader from "../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import { Menu } from "@szhsin/react-menu";
// Customizable Area End

import SalesManagerController, { Props, } from "./SalesManagerController.web";

class SalesManager extends SalesManagerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  cooSalesManager = () => {
    const { t, i18n }: any = this.props;
    const language = i18n.language;
    const currentPage = this.state.paginationData?.current_page as number;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <>
        <CoSalesManagerStyle>
          <Box dir={languageCondition(language, "rtl", "ltr")}>
            <Breadcrumbs aria-label="breadcrumb" dir={languageCondition(language, "rtl", "ltr")}>
              <Typography
                className="mangingDashboard"
                onClick={() => this.props.navigation.navigate("DashboardCompanyEmployee")}
                data-testId="myDashboardHeadingTestId"
              >
                {t("My Dashboard")}
              </Typography>
              <Typography
                className="mangingDashboard"
                onClick={() => this.props.navigation.navigate("DashboardCompanyEmployee")}
                data-testId="generalDashboardHeadingTestId"
              >
                {t("General Dashboard")}
              </Typography>
              <Typography className="assignedAccount" data-testId={"salesManagerHeadingTestId"}>
                {t("Assigned Sales Managers")}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography
            className="headingStyle bold-text"
            data-testId={"salesManagerHeadingTestId"}
            dir={languageCondition(language, "rtl", "ltr")}
          >
            {t("Assigned Sales Managers")}
          </Typography>

          <Grid container xs={12} spacing={2}>
            <Box className="filter-head-box">
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.salesManagerDataSave.map((item: any) => ({
                    label: item.attributes.sales_manager_name,
                    value: item.id,
                  })),
                ]}
                label="Select Sales Manager"
                language={language}
                t={t}
                onChange={this.handleSalesManagerChange}
                value={this.state.salesManagerId}
                data-testId="salesMangerSelectTestId"
              />
              <Button
                data-testId="searchBtnTestId"
                onClick={() => this.getAssignedSalesManagerList("1")}
                startIcon={<SearchOutlined />}
              >
                {t("Search")}
              </Button>
            </Box>
          </Grid>

          <Grid item sm={12} md={12} xs={12}>
            <Grid className="table-box-card">
              <Box className="table-top">
                <Box className="table-main-heading">
                  <h4 className="bold-text">{t("List of assigned Sales Manager")}</h4>
                </Box>
                <Box className="filter-head-box" style={{ margin: "0" }}>
                  <FilterSelect
                    t={t}
                    language={language}
                    label="Sort By"
                    option={[
                      { label: "Ascending", value: "asc" },
                      { label: "Descending", value: "desc" },
                    ]}
                    data-testId="sortBySelectTestId"
                    onChange={this.handleSortByChange}
                  />
                  <SearchInput
                    t={t}
                    language={language}
                    label="Search By name or id"
                    onChange={this.handleSearchInputChange}
                    data-testId="searchInputTestId"
                  />
                </Box>
              </Box>
              <Divider />
              <TableContainer component={Paper} className="table-container">
                <Table style={{ minWidth: 900 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Sales Manager")}
                      </TableCell>
                      <TableCell
                        data-testId="salesId"
                        align={languageCondition(language, "right", "left")}
                        className="bold-text"
                      >
                        {t("ID")}
                      </TableCell>
                      <TableCell className="bold-text" data-testId={"accountName"} align="left">
                        {t("Account Manager")}
                      </TableCell>
                      <TableCell align="left" className="bold-text" data-testId={"complexName"}>
                        {t("Assigned Complexes")}
                      </TableCell>
                      <TableCell align="left" data-testId={"buildingName"} className="bold-text">
                        {t("Assigned Buildings")}
                      </TableCell>
                      <TableCell className="bold-text" data-testId={"ownerName"} align="left">
                        {t("Assigned Owners")}
                      </TableCell>
                      <TableCell className="bold-text" align="left" data-testId={"devName"}>
                        {t("Assigned Property Developers")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.salesManagerDataSave.map((item: any, index: number) => {
                      return (
                        <TableRow>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {(currentPage - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            data-testId={`tableSalesManagerNameTestId${index}`}
                          >
                            {item.attributes.sales_manager_name}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>{item.id}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.account_manager}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.assigend_complexes}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.assigend_buildings}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.assigend_owners}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.assigend_property_developers}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                          <IconButton data-testId="sales-manager-menu" onClick={this.handleClick}>
                                  <MoreVertIcon />
                                </IconButton>
                                <Popover
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={this.handleClose}
                                                    data-testId={"popoverTestId"}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            marginLeft: "20px",
                                                        },
                                                    }}
                                                    elevation={1}
                                                >
                                                  <MenuItem data-test-id="view-menu" onClick={() => this.handleNavigation(item.id)}>
                                {t("View Details")}
                              </MenuItem>
                                                </Popover>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className="table-bottom">
                <PaginationModule
                  pagination={this.state.paginationData}
                  handlePagination={this.handlePagination}
                  page={this.state.paginationData?.current_page}
                  data-testId="contractListPagintionTestId"
                />
              </Box>
            </Grid>
          </Grid>
        </CoSalesManagerStyle>
      </>
    );
  };
    // Customizable Area End

    render() {
        // Customizable Area Start
    return (
      <CEHeaderComponentWeb>
        {this.cooSalesManager()}

        <AlertError
          show={this.state.showError}
          handleClose={this.handleAlertError}
          message={this.state.showErrorMessage}
          data-testId={"errorAlertTestId"}
        />
        <Loader loading={this.state.loader} />
      </CEHeaderComponentWeb>
    );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(SalesManager);
export { SalesManager };

const CoSalesManagerStyle = styled(Box)({
  padding: "0 35px",
  "& .mangingDashboard": {
    fontSize: "18px",
    fontWeight: 400,
    color: "#3D424B",
    cursor: "pointer",
  },
  "& .assignedAccount": {
    fontSize: "18px",
    fontWeight: 400,
    color: "#2b6fed",
  },
  "& .headingStyle": {
    fontSize: "28px",
    marginTop: "10px",
  },
  "& .table-container": {
    boxShadow: "none",
  },
});

// Customizable Area End