// Customizable Area Start
import * as React from "react";
// custom components
import { Button, Grid, Box, Dialog, DialogTitle, DialogActions, TextField } from "@material-ui/core";
import "../assets/css/style.scss";
import { Formik, Form, ErrorMessage } from "formik";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController.web";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Back_btn, building, Building1, city, Complex, country, ReqHome, unit } from "./assets";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AlertErrorWeb from "../../../components/src/AlertError.web";


class RegisterAddressLinkLink extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount(): Promise<void> {
    this.getCountry();
  }

  render() {
    // @ts-ignore
    const { t } = this.props;
    return (
      <>
        <Grid container spacing={2} className="auth-container linkageUnit">
          <Grid item xs={12} md={7} className="auth-cols inputPlaceholderRegistration linkageUnit" style={{ justifyContent: "unset" }}>
            <div>
              <Grid container style={{ margin: "1rem", width: "90%", marginBottom: 0 }} className="linkageUnit">
                <Grid xs={12} className="linkageUnit">
                  <img src={Back_btn} onClick={() => window.history.back()} style={{ marginTop: '1rem', marginLeft: '0rem' }} className="linkageUnitBackButton" alt={"back-button"} />
                </Grid>
              </Grid>

              <Grid container style={{ marginLeft: "16px", marginTop: "2rem", width: "90%" }} className="linkageUnit">
                <Grid xs={12} className="linkageUnit">
                  <p className="text-left bold-text linkageUnit" style={{ fontSize: "1.5rem", fontWeight: 700 }}>
                    <Heading userType={this.state.typeOfUser} t={t} />
                  </p>
                </Grid>
              </Grid>
              <Grid container style={{ margin: "1rem", width: "90%", marginTop: '0.5rem' }}>
                <Grid xs={12}>
                  <SubHeading userType={this.state.typeOfUser} t={t} />
                </Grid>
              </Grid>
              <Formik
                initialValues={{
                  selectCountry: " ",
                  selectCity: " ",
                  selectComplex: " ",
                  selectBuilding: " ",
                  selectUnit: " ",
                }}
                validationSchema={this.addressSchema()}
                validateOnMount={true}
                onSubmit={() => this.setState({ showDialog: true })}
              >
                {({ values, setFieldValue }) => (
                  <Form translate="yes" className="commonForm linkageUnit" style={{ height: "450px", position: "relative" }}>
                    <Grid container style={{ margin: "1rem", marginBottom: 0, width: "90%" }} className="linkageUnit">
                      <Grid xs={12} className="formGroup customSelect linkageUnit">
                        <FormControl variant="outlined" fullWidth className="linkageUnit">
                          <span className="frmLeftIcons">
                            <img src={country} className="frm-icons" alt="House Icon" />
                          </span>
                          <Select
                            value={values.selectCountry} name="selectCountry" className="SelectCuntryLinkageUnit"
                            labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                            onChange={(e) => { this.handleChange(e); setFieldValue("selectCountry", e.target.value); }}
                            label="Country" style={{ borderRadius: 25, color: "#b5b5b5", paddingLeft: 55 }}
                            // autoFocus // Keep the selected item in view
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                  overflow: "auto",
                                },
                              },
                            }}
                          >

                            <MenuItem value=" " style={{ color: "#7a7878" }}>Select Country</MenuItem>
                            {
                              this.state.allContries?.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage className="text-error countrySelectLinkageUnit" component="Typography" name="selectCountry" />
                      </Grid>
                    </Grid>
                    <Grid container style={{ margin: "1rem", marginTop: 0, marginBottom: '-1rem', width: "90%" }} className="linkageUnit">
                      <Grid xs={12} className='formGroup customSelect linkageUnitCity' >
                        <FormControl variant="outlined" fullWidth className="linkageUnitCity">
                          <span className="frmLeftIcons linkageUnitCity">
                            <img src={city} className="frm-icons linkageUnitCity" alt="House Icon" />
                          </span>
                          <Select
                            name="selectCity" value={values.selectCity} className="SelectCityLinkageUnit"
                            labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                            onChange={(e) => { this.handleChange(e); setFieldValue("selectCity", e.target.value); }}
                            label={t("City")} style={{ borderRadius: 25, color: "#b5b5b5", paddingLeft: 55 }}
                            autoWidth
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 500,
                                  overflow: "auto",
                                  paddingRight: "29%", // Use colon instead of semicolon here
                                },
                              },
                            }}
                          >

                            <MenuItem value=" " style={{ color: "#7a7878" }}>{t("Select city")}</MenuItem>
                            {this.state.allCity &&
                              this.state.allCity.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage className="text-error linkageUnitCity" component="Typography" name="selectCity" />
                      </Grid>
                    </Grid>
                    <Box className="commonForm linkageUnitComplex" style={{ marginTop: 0, marginBottom: 0, }}>
                      <Box className="formGroup customSelect linkageUnitComplex" style={{ marginBottom: "5px" }}>
                        <Box className="formInputGrp linkageUnitComplex">
                          <span className="frmLeftIcons linkageUnitComplex" style={{ left: '34px', top: '27%' }}>
                            <img src={Complex} className="frm-icons linkageUnitComplex" alt="House Icon" />
                          </span>
                          <Autocomplete
                            // @ts-ignore
                            id="combo-box-demo" className={this.state.selectComplex === "" && "autoCompleteSearchComplex"} options={this.state.allComplex}
                            // @ts-ignore
                            getOptionLabel={(option: any) => option.label || ""} style={{ borderRadius: 25, color: "#b5b5b5", paddingLeft: 20, width: '89%' }}
                            onChange={(e: any, newValue) => { this.handleInputChangeCOm(e, newValue, false, setFieldValue); setFieldValue("selectComplex", newValue); }}
                            placeholder={t("Search Complex")}
                            renderInput={(params) => <TextField {...params} className={this.state.selectComplex ? 'complex-input' : ''} placeholder={t("Search Complex")} variant="outlined" />}
                          />
                          <span className="frmLeftIcons linkageUnitComplex" style={{ top: "1.5rem" }}>
                          </span>
                        </Box>
                      </Box>
                      {/*@ts-ignore*/}
                      <ErrorMessage className="text-errorSelect linkageUnitComplex" component="Typography" name="selectComplex" style={{ marginLeft: "30px", marginBottom: "10px" }} />
                    </Box>
                    {
                      this.state.showBuildings ?
                        <Grid container style={{ margin: "1rem", marginBottom: 0, width: "90%", marginTop: "15px" }} className="linkageUnitBuilding">
                          <Grid xs={12} className='formGroup customSelect linkageUnitBuilding'>
                            <FormControl variant="outlined" fullWidth className="linkageUnitBuilding">
                              <span className="frmLeftIcons linkageUnitBuilding">
                                <img src={building} className="frm-icons linkageUnitBuilding" alt="House Icon" />
                              </span>
                              <Select
                                name="selectBuilding" value={values.selectBuilding} className="SelectBuildingLinkageUnit"
                                labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                                onChange={(e) => { this.handleChange(e); setFieldValue("selectBuilding", e.target.value); }}
                                label="Building" style={{ borderRadius: 25, color: "#b5b5b5", paddingLeft: 55 }}
                              >
                                <MenuItem value=" " style={{ color: "#7a7878 " }}>Select building</MenuItem>
                                {this.state.allBuilding &&
                                  this.state.allBuilding.map((item: any) => (
                                    <MenuItem key={item.id} value={item}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <ErrorMessage className="text-error linkageUnitBuilding" component="Typography" name="selectBuilding" />
                          </Grid>
                        </Grid>
                        :
                        <Grid container style={{ margin: "1rem", marginBottom: 0, width: "90%", marginTop: "15px" }} className="linkageUnitBuildingNoData" />
                    }
                    <Grid container style={{ margin: "1rem", marginTop: 0, marginBottom: 0, width: "90%" }} className="linkageUnitUnit">
                      <Grid xs={12} className='formGroup customSelect linkageUnitUnit'>
                        <FormControl variant="outlined" fullWidth className="linkageUnitUnit">
                          <span className="frmLeftIcons linkageUnitUnit">
                            <img src={unit} className="frm-icons linkageUnitUnit" alt="House Icon" />
                          </span>
                          <Select
                            name="selectUnit" value={values.selectUnit} className="unitListLinkageunit"
                            labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                            onChange={(e) => { this.handleChange(e); setFieldValue("selectUnit", e.target.value); }}
                            label={t("Unit")} style={{ borderRadius: 25, color: "#b5b5b5", paddingLeft: 55 }}
                          >
                            <MenuItem value=" " style={{ color: "#7a7878 " }}>{t("Select unit")}</MenuItem>
                            {this.state.allUnit &&
                              this.state.allUnit.map((item: any) => (
                                <MenuItem key={item.id} value={item}>
                                  {item.apartment_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage className="text-error linkageUnitUnit" component="Typography" name="selectUnit" />
                      </Grid>
                    </Grid>
                    <Box
                      className="customButton linkageUnit"
                      style={{ width: "90%", margin: "1rem", position: "absolute", bottom: "0" }}
                    >
                      <Button variant="contained" className="linkageUnit" type="submit">{t("SEND REGISTRATION REQUEST")}</Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </div>
          </Grid>
          <Grid item xs={12} md={5} className="auth-cols linkageUnit">
            <Box className="right-block linkageUnit" display={{ xs: "none", md: "flex" }}>
              <img src={Building1.default} className="building-logo linkageUnit" alt="" />
            </Box>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.showDialog} onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
          className="diloag-wrapper linkageUnit"
          PaperProps={{
            style: { borderRadius: "15px", margin: 0, padding: '10px 25px 0px 25px' },
          }}
        >
          <Box className="diloag-body linkageUnit">
            <Box className="diloag-header 1 linkageUnit" style={{ flexDirection: "column", border: 'none' }}>
              <img src={ReqHome} className="tenet-logo linkageUnit" alt="" />
              <DialogTitle
                className="alert-dialog-title1 bold-text linkageUnit" id="alert-dialog-title"
                style={{ overflow: "visible", width: "auto", fontSize: 20 }}
              >
                <h1 className="bold-text ">{t("Sure you want to register this unit?")}</h1>
              </DialogTitle>
              <p style={{ paddingTop: 20 }} className="linkageUnit">
                {
                  sessionStorage.getItem('selectedUserType') == 'Tenant' ? <>
                    {t("Are you sure that you want to register the unit")}{" "}
                    {this.state.selectUnit && this.state.selectUnit.apartment_name} {t("of")}{" "}
                    {this.state.selectBuilding && this.state.selectBuilding.name}?
                  </> :
                    <>
                      {t("Are you sure that you want to register the unit")}{" "}
                      {this.state.selectUnit && this.state.selectUnit.apartment_name} {t("of")}{" "}
                      {this.state.selectBuilding && this.state.selectBuilding.name} {t("as a unit that you own or manage?")}
                    </>
                }
              </p>
            </Box>
            <Box className="dialog-footer desktop-ui" style={{ display: "flex", justifyContent: "center" }}>
              <DialogActions className="customButton">
                <Button
                  variant="contained"
                  className="SubmitButtonLinkage"
                  onClick={() => { sessionStorage.clear(); this.createRequest(); }}>
                  {t("Yes, Register")}
                </Button>
                <Button onClick={() => this.setState({ showDialog: false })} variant="text" className="dialogClose">
                  {t("No, Don’t Register")}
                </Button>
              </DialogActions>
            </Box>
          </Box>
        </Dialog>
        <AlertErrorWeb show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
      </>
    );
  }
}

const Heading = ({ userType, t }: any) => {
  return (
    <>
      {
        userType == 'Tenant' ? <>
          {t("Select Building and Unit")}
        </>
          :
          <>
            {t("Linking a Unit")}
          </>
      }
    </>
  )
}

const SubHeading = ({ userType, t }: any) => {
  return (
    <>
      {
        userType == 'Tenant' ?
          <>
            {t("Please select the unit you would like to link with your account.If you have more than one unit you can link the other ones later on.")}
          </>
          :
          <>
            <p className="text-left">{t("Please select the appropriate details of the unit")}</p>
          </>
      }
    </>
  )
}
//@ts-ignore
export default withTranslation()(withRouter(RegisterAddressLinkLink));


// Customizable Area End