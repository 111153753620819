// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Divider,
  TableRow,
  TextField,
  IconButton,
  Backdrop,
  Modal,
  Fade,
  MenuItem
} from "@material-ui/core";
import { Menu, } from "@szhsin/react-menu";
import CloseIcon from '@material-ui/icons/Close';
import SubscriptionListingController, { Props } from "./SubscriptionListingController";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { t } from "i18next";
import { TabsComponent } from "../../../components/src/TabsComponent";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import { withTranslation } from "react-i18next";
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AnnouncementsFilter } from "../../../components/src/AnnouncementsFilter";
import { Link } from "react-router-dom";
import { imgPropertyDevelopers, imgGeneralAssemblies, imgOwners, imgPropertyManagers } from "./assets";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";


const registerOptionsRow1 = [
  {
    icon: imgPropertyDevelopers,
    title: 'Property Developers',
    value:"property_developer",
    link: '/CompanyEmployee/AddSubscription'
  },
  {
    icon: imgGeneralAssemblies,
    title: 'General Assemblies',
    value:"general_assembly",
    link: '/CompanyEmployee/AddSubscription'
  },
]
const registerOptionsRow2 = [
  {
    icon: imgOwners,
    title: 'Owners',
    value:"owner",
    link: '/CompanyEmployee/AddSubscription'
  },
  {
    icon: imgPropertyManagers,
    title: 'Property Managers',
    value:"property_manager",
    link: '/CompanyEmployee/AddSubscription'
  }
]

export class SubscriptionListing extends SubscriptionListingController {
  constructor(props: Props) {
    super(props);
  }

  viewProfiledata=(row:any)=>{
    return(
      <>
      {this.state.filterList==="property_developer"&&
      <MenuItem className="buildid">
      <Link to={`/CompanyEmployee/realestate-company-details/${row.attributes.subscriber_id}`}>{t("View Profile")}</Link>
    </MenuItem>
     }
       {this.state.filterList==="general_assembly"&&
      <MenuItem className="buildid">
      <Typography onClick={this.handleBuildingMap}>{t("View building")}</Typography>
    </MenuItem>
     }
      <MapDialog
          t={this.props.t}
          IsOpen={this.state.openBuildingMap}
          CloseDialog={this.handleCloseBuildingMap}
          lat={row.attributes?.complex_name?.data?.attributes?.lat || 0}
          long={row.attributes?.complex_name?.data?.attributes?.long || 0}
          data-testId={"viewBuildingModal"}
        />
       {this.state.filterList==="owner"&&
      <MenuItem className="buildid">
      <Link to={`/CompanyEmployee/InvitationReport/UserProfile/${row.id}`}>{t("View Profile")}</Link>
    </MenuItem>
     }
       {this.state.filterList==="property_manager"&&
      <MenuItem className="buildid">
      <Link to={`/CompanyEmployee/realestate-company-details/${row.attributes.subscriber_id}`}>{t("View Profile")}</Link>
    </MenuItem>
     }
     </>
    )
  }



  tableBody=()=>(
    
      this.state.subscriptionList.map((row: any, i: number) => {
        const statusMap :any= {
          "deactive": "deactivated",
          "freeze": "frozen",
          "upgrade_request": "Upgrade Request",
          "freeze_request": "Freeze Request",
          "extend_request": "Extend Request",
          "deactive_request": "Deactive Request"
        };
        
        const name = statusMap[row?.attributes?.status] || row?.attributes?.status;
       
        return <TableRow key={i}>
          <TableCell>{(this.state.page - 1) * 10 + i + 1}</TableCell>
          {this.state.tabNo===0 && <TableCell>{row.attributes.subscriber_name}</TableCell> }
          
          {(this.state.accountType ==="coo" && this.state.tabNo===0) &&  <TableCell>{row.attributes?.complex_name?.data?.attributes?.name || "_"}</TableCell> }
          {this.state.tabNo===1 &&  <TableCell>{row.attributes?.complex_name?.data?.attributes?.name || "_"}</TableCell> }
          {(this.state.tabNo===2 )&&  <TableCell>{row.attributes?.owner_name||"-"}</TableCell>}
          {(this.state.tabNo===3) &&  <TableCell>{row.attributes?.subscriber_name}</TableCell>}
         {this.state.tabNo!==0 && <TableCell>{row.attributes.total_units || "-"}</TableCell>}
          <TableCell>{row.attributes.plan.data.attributes.title}</TableCell>
          <TableCell>{this.convertDate(row.attributes.expiry_date)}</TableCell>
          <TableCell>{row.attributes.ends_in_days==="Expired"?"Expired":<>{row.attributes.ends_in_days }<span  style={webStyle.dayStyle}>days</span></>}</TableCell>
          <TableCell className="bold-text">
           <span style={webStyle[row.attributes.status]}> {name}</span> 
           
          </TableCell>
          <TableCell  className="view-page-menu buildid" id="detailsId">
                                <Menu
                                  menuButton={
                                    <IconButton>
                                      <MoreVertIcon/>
                                    </IconButton>
                                  }
                                  className="buildid"
                                  style={webStyle.btnMenu}
                                >
                                 {this.viewProfiledata(row)}
                                 
                                  <MenuItem
                                    className="sm-cancel-menu buildid"
                                  >
                                    <Link to={`/CompanyEmployee/SubsciptionDetails/${row.id}`}>

                                   {t("View subscription")}
                                   </Link>
                                  </MenuItem>
                                </Menu>
                              </TableCell> 
         
        </TableRow>
      })
    
  )
  render() {
    return (
      <MainScreen>
        <>
          <Box maxWidth='xl' style={webStyle.detailsBox} >
            <Box>
              <Typography style={{ fontSize: '18px',color:"rgb(43, 111, 237)" }}>{t("Manage Subscriptions")}</Typography>
            </Box>
            <Box style={webStyle.titleBoxStyle}>
              <Typography style={webStyle.companyTitleStyle} className="bold-text">{t("Manage Subscriptions")}</Typography>
              <Box style={webStyle.btnBoxStyle}>
                <Button style={webStyle.configBtn} ><span className="bold-text" onClick={()=>{this.handleConfigureModal()}} data-testid="configureBtn">{t("Configure Notification")}</span></Button>
                <Button style={webStyle.registerBtn} ><span className="bold-text" onClick={this.handleOpenRegisterModal} data-testid="register_btn">{t("Create New Subscrtiption")}</span></Button>
              </Box>
            </Box>
            <Box style={webStyle.tabsBox}>
              <Box>
                <TabsComponent
                  tabNo={this.state.tabNo}
                  handleChange={this.handleTabChange}
                  tabs={this.state.tabs}
                />
              </Box>
            </Box>
            <Box style={webStyle.selectFilter} className="estate_table_select_boxes">
              <Box style={webStyle.selectBoxLeft} className="estate_table_select_boxes_left">
                <Box className={"top-bar-filter ChairmanInvoices"} id="ChairmanInvoices">
                  <AnnouncementsFilter t={t} classComponent={this} userrole={this.state.userrole} filterList={this.state.filterList} i18n={this} />
                  <Box className="filter-filter">
                    <Button
                      className="handleSearchFilterForInvoices search-btn-estate"
                      onClick={this.handleSubmit}
                      startIcon={<SearchIcon/>}
                    >
                      {t("Search")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={webStyle.tableContainerStyle} className="subscription_table_select_boxes_container">
              <Box style={webStyle.tableHeaderStyle} className="subscription_table_select_boxes_header">
                <h3   className="bold-text subscription_table_heading2">
                  {
                    t(this.state.titleTable)
                  }
                </h3>
                <TextField
                  label="Search"
                  variant="outlined"
                  data-testid="Search-subscription"
                  className="table_detail_search subscription_detail_search_new"
                  onChange={(e)=>{this.onChange(e)}}
                  style={webStyle.searchInputStyle}
                  placeholder={t("Search By "+this.state.searchBy)}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                      >
                        <SearchIcon />
                      </IconButton>
                    ),
                    style: webStyle.innerInput
                  }}
                />
              </Box>
              {
                this.state.subscriptionList?.length ? <Table className="table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell className="bold-text">{t("#")}</TableCell>
                      {this.state.tabNo===0 &&  <TableCell className="bold-text">{t("Company Name")}</TableCell>}
                      
                      {(this.state.accountType ==="coo" && this.state.tabNo===0) &&  <TableCell className="bold-text">{t("Complex Name")}</TableCell>}
                      {(this.state.tabNo===1) &&  <TableCell className="bold-text">{t("Complex")}</TableCell>}
                      {(this.state.tabNo===2 || this.state.tabNo===3) &&  <TableCell className="bold-text">{t("Name")}</TableCell>}
                      {this.state.tabNo!==0 && <TableCell className="bold-text">{t("Total Units")}</TableCell> }
                      <TableCell className="bold-text">{t("Plan")}</TableCell>
                      <TableCell className="bold-text">{t("Ends On")}</TableCell>
                      <TableCell className="bold-text">{t("Ends In")}</TableCell>
                      <TableCell className="bold-text">{t("Status")}</TableCell>
                      <TableCell className="bold-text"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   {this.tableBody()}
                  </TableBody>
                </Table> : <Box style={webStyle.noRecordStyles}>
                  <Typography variant="body1">
                    {t("No Subscriptions Found")}
                  </Typography>
                </Box>
              }
              <Divider />
              <Box style={webStyle.tableFooterStyle} className="paginate_details">
                <PaginationModule pagination={this.state.pagination}
                  handlePagination={this.handleVistorPagination}
                  page={this.state.page} />
              </Box>
            </Box>
          </Box>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalStyle"
            // @ts-ignore
            open={this.state.registerModal}
            onClose={this.handleCloseRegisterModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            {/*@ts-ignore*/}
            <Fade in={this.state.registerModal}>
              <Box style={webStyle.contextModal}>
                <Box style={webStyle.contentModal}>
                  <Typography className="bold-text" style={webStyle.modalRegisterHeading}>{t("Create New Subscription")}</Typography>
                  <CloseIcon style={webStyle.closeIcon} data-testid="close_registere" onClick={this.handleCloseRegisterModal} />
                </Box>
                <Box style={webStyle.buildingOptions}>
                  {
                    registerOptionsRow1.map((icon, i) => {
                      return <Box key={i} style={webStyle.boxOption}>
                        <Link to={{
                          pathname: icon.link,
                          state: { userRole: icon.value  }
                        }}
                          style={webStyle.linkStyle}>
                          <Box style={webStyle.borderCircle}>
                            <img src={icon.icon} style={webStyle.iconSize} />
                          </Box>
                          <Typography>{icon.title}</Typography>
                        </Link>
                      </Box>
                    })
                  }
                </Box>
                <Box style={webStyle.buildingOptions}>
                  {
                    registerOptionsRow2.map((icon, i) => {
                      return <Box
                        key={i}
                        style={webStyle.boxOption} >
                        <Link to={{
                          pathname: icon.link,
                          state: { userRole: icon.value }
                        }}
                          style={webStyle.linkStyle}>
                          <Box style={webStyle.borderCircle}>
                            <img src={icon.icon} style={webStyle.iconSize} />
                          </Box>
                          <Typography>{icon.title}</Typography>
                        </Link>
                      </Box>
                    })
                  }
                </Box>
              </Box>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalStyle"
            open={this.state.configureModal}
            onClose={this.handleConfigureModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={this.state.configureModal}>
              <Box style={webStyle.configureModel}>
                <Box style={{ ...webStyle.contentModal, ...webStyle.configureHeader }}>
                  <Typography className="bold-text" style={webStyle.modalRegisterHeading}>Configure Notification</Typography>
                  <CloseIcon style={webStyle.configureCloseIcon} data-testid="close_register" onClick={this.handleConfigureModal} />
                </Box>
                <Divider />
                <Box sx={{...webStyle.configureBody}}>
                  <Typography style={webStyle.configureSubtitle}>Notify customers for renew before ending of subscription</Typography>
                    <TextField
                      label="Days"
                      variant="outlined"
                      value={this.state.configureValue}
                      style={{
                        ...webStyle.configureInputStyle, 
                      }}
                      data-testid='changeconfigurevalue'
                      onChange={(e)=>{
                        const value = e.target.value.split('').reduce((acc, char) => {
                          if (/\d/.test(char)) {
                            acc += char;
                          }
                          return acc;
                        }, '');
                        this.changeConfigureData(value);
                      }}
                    />
                      <Box sx={{...webStyle.configureModelButton}}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={webStyle.mainBtn}
                      onClick={this.handleConfigureModal}
                    >
                      {t("CANCEL")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={webStyle.mainBtnActive}
                      onClick={()=>{this.submitConfigureModal()}}
                    >
                      {t("CONFIRM")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </>
      </MainScreen >
    );
  }
}
const webStyle: any = {
  selectBoxLeft: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  rowOptions: {
    position: 'absolute' as CSSProperties['position'],
    background: '#fff',
    width: '160px',
    left: '-115px',
    boxShadow: '0px 0px 6px rgba(87,148,201,0.18)',
    zIndex: '9999999' as CSSProperties['zIndex'],
    padding: '15px'
  },
  option1: {
    marginBottom: '15px' as CSSProperties['marginBottom']
  },
  rowList: {
    listStyle: 'none'
  },
  detailsBox: {
    padding: '30px'
  },
  tabsBox: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  registerBtn: {
    background: '#FC8434',
    color: '#fff',
    height: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '8px'
  },
  configBtn: {
    background: '#Ffffff',
    color: '#FC8434',
    height: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '8px',
    border: '1px solid #FC8434',
    marginRight: "8px",
    borderColor: '#FC8434'
  },
  searchInputStyle: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '8px',
    height: '40px',
    padding: '0px',
    color: '#212121'
  },
  configureInputStyle: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '8px',
    height: '50px',
    padding: '0px',
    color: '#212121',
    display: 'flex',
    justifyContent: 'center'
  },
  optionsStyle: {
    position: 'relative' as CSSProperties['position']
  },
  moreIconStyle: {
    color: 'rgba(136,136,136,0.6)',
    cursor: 'pointer'
  },
  tableHeaderStyle: {
    padding: '20px',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableContainerStyle: {
    background: '#fff'
  },
  tableFooterStyle: {
    padding: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableHeadingStyle: {
    fontSize: '22px',
    display: 'flex',
    alignItems: 'center'
  },
  tabHead:{
    fontSize: '22px',
    display: 'flex',
    alignItems: 'center',
    padding:"20px"
  },
  innerInput: {
    padding: '0px',
    color: '#212121'
  },
  titleBoxStyle: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  companyTitleStyle: {
    fontSize: '32px'
  },
  dayStyle:{
 paddingLeft:"5px"
  },
  btnBoxStyle: {
    display: 'flex',
  },
  noRecordStyles: {
    padding: '25px',
    display: 'flex',
    textAlign: 'center' as CSSProperties['textAlign']
  },
  companyTag: {
    background: 'rgb(30,198,91,0.12)',
    color: '#1EC65B',
    textAlign: 'center' as CSSProperties['textAlign'],
    padding: '5px 10px',
    borderRadius: '25px',
    fontSize: '0.875rem',
    whiteSpace: 'nowrap'
  },
  active: {
    background: 'rgb(30,198,91,0.2)',
    color: 'rgb(30 198 91)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  upgrade_request: {
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  freeze_request: {
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  pending: {
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  extend_request: {
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  deactive_request:{
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },

 
  rejected: {
    background: "rgba(236, 135, 152, 0.12)",
    color: "red",
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  suspended: {
    background: "rgba(236, 135, 152, 0.12)",
    borderRadius:"20px",
    textTransform: "capitalize",
    color: "red",
    padding:"5px 15px",
  },
  upgrade_rejected:{
    background: "rgba(236, 135, 152, 0.12)",
    textTransform: "capitalize" ,
    padding:"5px 15px",
    color: "red",
    borderRadius:"20px",
  },
  deactive_rejected:{
    padding:"5px 15px",
    borderRadius:"20px",
    color: "red",
    background: "rgba(236, 135, 152, 0.12)",
    textTransform: "capitalize" 
  },
  extend_rejected:{
    color: "red",
    background: "rgba(236, 135, 152, 0.12)",
    padding:"5px 15px",
    textTransform: "capitalize",
    borderRadius:"20px",
  },
  freeze:{
    background: "rgba(236, 135, 152, 0.12)",
    color: "red",
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  deactive: {
    background: "#D8D8D8",
    color: "#45474B",
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },

 
  contextModal: {
    width: "25%",
    marginTop: '15px',
    backgroundColor: "white",
    padding: '20px',
    borderRadius: "20px"
  },
  contentModal: {
    display: "flex",
    flexDirection: "column",
    lignItems: "center",
    position: 'relative',
    justifyContent: 'center'
  },
  configureSubtitle:{fontSize: '0.8rem',paddingBottom:'10px'},
  configureHeader:{padding:'0px 20px',paddingBottom: '10px'},
  configureBody:{padding:'10px 20px',paddingBottom:'0px',},
  configureModelButton:{textAlign: 'end',pt:2},
  configureModel:{
    opacity: '1',
    marginTop: '15px',
    backgroundColor: 'white',
    padding: '20px 0px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  mainBtn: {
    minWidth: "100px",
    padding: '12px 30px',
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
    backgroundColor: "#ffffff",
    color: "rgb(43, 111, 237)",
    border: '1px solid rgb(43, 111, 237)'
  },
  mainBtnActive: {
    minWidth: "100px",
    backgroundColor: "rgb(43, 111, 237)",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    color: "#ffffff",
    marginLeft: "10px",
    borderRadius: "8px",
    padding: '12px 30px',
    fontSize: "15px",
  },
  buildingOptions: {
    display: 'flex',
    marginTop: '30px',
    justifyContent: 'space-evenly'
  },
  borderCircle: {
    border: '1px solid #ececec',
    borderRadius: '50%',
    height: '80px',
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px'
  },
  boxOption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '180px',
  },
  linkStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '180px'
  },
  closeIcon: {
    position: 'absolute',
    right: '0px',
    cursor: 'pointer'
  },
  configureCloseIcon: {
    position: 'absolute',
    right: '20px',
    cursor: 'pointer'
  },
  iconSize: {
    width: "40px",
    height: "40px"
  },
  selectFilter: {
    marginTop: '30px',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
};

export default withTranslation()(SubscriptionListing)

// Customizable Area End
