//@ts-ignore
//@ts-nocheck


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from 'yup';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import i18next from "i18next";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  error: string | null;
  userType: string | null;
  stayLogin:boolean;
  showError:boolean;
  isMultiRole:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;

  apiEmailLoginCallId: any;
  validationApiCallId: any;
  apiRegistrationRequestCallId: any;
  deleteRequestCallId:any;
  emailReg: RegExp;
  labelTitle: string = "";


  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      error: null,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      userType:'',
      stayLogin:false,
      showError:false,
      isMultiRole:false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  showError = () => {
   if(this.state.error){
     this.setState({
       showError:true
     })
   }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.manageLoginResponse(responseJson);
        }
        else if (apiRequestCallId === this.deleteRequestCallId) {
          this.manageDeleteRequest(responseJson);
        }
        else if (apiRequestCallId === this.apiRegistrationRequestCallId) {
          this.manageRegistrationRequest(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleLogout = () => {
    localStorage.clear();
    this.props.history.push("/")
  }

  manageRegistrationRequest = (responseJson: any) => {
    if (responseJson && responseJson?.data ) {
      const  registrationRequest = responseJson?.data[0]
      const status :any = registrationRequest?.attributes?.status;
      if( status === "Requested"){
        this.props.history.push("/RegistrationRequest");
        this.setState({registrationRequest, requestdeleteId :registrationRequest.id,loading: false})
      }
      else if (localStorage.getItem("userType") === "Owner" || localStorage.getItem("userType") === "Property Manager") {
        this.setState({ loading: false })
      } else {
        this.setState({loading: false})
      }
    } else if (responseJson?.errors) {
      let error = Object.values(responseJson.errors[0])[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.setState({loading: false})
    this.showError()
  }
  manageDeleteRequest = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({loading: false,showDialog:false})
      this.props.history.push("/")
    } else if (responseJson?.errors)
    {
      let error = Object.values(responseJson.errors[0])[0] as string;
      this.setState({ error });
    } else
    {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.setState({loading: false,showDialog:false})
    this.showError()
  }



  
  manageLoginResponse = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      localStorage.setItem("userToken", responseJson?.meta?.token)
      localStorage.setItem("userId", responseJson?.meta?.id)
      localStorage.setItem("society_id", responseJson.meta?.society_id)
      localStorage.setItem("complexName", responseJson.meta?.complex_name)
      localStorage.setItem("language", "en");
      i18next.changeLanguage("en");
      if(!responseJson.meta?.society_id){
        this.props.history.push("/RegistrationRequest")
        return
      }
      const isResidentOwner = this.getCount(responseJson,"Owner Resident")
      const isPropertyManager= this.getCount(responseJson,"Property Manager")
      const isTenant = this.getCount(responseJson,"Tenant")
      const isOwner = this.getCount(responseJson,"Owner")
      this.checkAssignedDashboard(isResidentOwner,isPropertyManager,isTenant,isOwner)
    } else if (responseJson?.errors) {
      let error = Object.values(responseJson.errors[0])[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.setState({loading: false})
    this.showError()
  }

  checkAssignedDashboard = (isResidentOwner:any,isPropertyManager:any,isTenant:any,isOwner:any) => {
    if(isResidentOwner.length > 0 || isPropertyManager.length > 0 || isTenant.length > 0 ||  isOwner.length > 0){
      if(isOwner.length > 0 && isTenant.length > 0){
        this.setState({
          isMultiRole:true
        })
      }else{
        this.redirectToDashboard(isResidentOwner,isPropertyManager,isTenant,isOwner)
      }
    }else{
      this.setState({
        error:"You have no right to access this dashboard"
      })
    }
    this.getRegistrationRequest();
    this.setState({loading: false})
  }

  redirectToDashboard = (isResidentOwner:any,isPropertyManager:any,isTenant:any,isOwner:any) => {
    if(isResidentOwner.length > 0){
      this.goToOwnerResidentDashboard()
    }
    if(isPropertyManager.length > 0){
      this.goToPropertyManagerDashboard()
    }
    if(isTenant.length > 0){
      this.goToTenantDashboard()
    }
    if(isOwner.length > 0){
      this.goToOwnerDashboard()
    }
  }

  goToOwnerResidentDashboard = () => {
    localStorage.setItem("userType","Owner Resident")
    this.props.history.push("/ResidentDashboard")
  }

  getCount = (responseJson:any,dashboardName:string) => {
    return responseJson.meta.role.filter((item)=> {
      return item.name === dashboardName
    })
  }

  goToTenantDashboard = () => {
    localStorage.setItem("userType","Tenant")
    this.props.history.push("/ResidentDashboard")
  }

  clear = () => {
    localStorage.clear();
    this.props.history.push("/")
  }

  goToOwnerDashboard = () => {
    localStorage.setItem("userType","Owner")
    this.props.history.push("/OwnerDashboard")
  }
  goToPropertyManagerDashboard = () => {
    localStorage.setItem("userType","Property Manager")
    this.props.history.push("/OwnerDashboard")
  }


  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  LoginSchema() {
    const validations = Yup.object().shape({
      email: Yup.string()
        .email('Invalid email format')
        .strict(true)
        .trim()
        .required('Email is required.'),
  
      password: Yup.string()
        .required('Password is required')
        .matches(/^\S*$/, 'Password cannot contain spaces'),
    });
    return validations;
  }
  
  doLogIn = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      email: values.email,
      password: values.password
    };

    const data = {
      type: "email_account",
      attributes: attrs,
      // user_type: values.userType,
      stay_login: values.stayIn
    };

    const httpBody = {
      data: data
    };

    this.setState({loading: true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signinAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getRegistrationRequest = () => {
    try {
      const header = {
        token :localStorage.getItem("userToken")
      };

      //const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiRegistrationRequestCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_request_management/requests`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };


  deleteRequestById = () => {
    //console.log("this.state?.requestdeleteId deleleleleel}==========>",this.state?.requestdeleteId);
    console.log("TEST")
    const id : any = this.state?.requestdeleteId;
    try {
      const header = {
        token :localStorage.getItem("userToken")
      };

      //const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteRequestCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_request_management/requests/${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDelete
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };
  // Customizable Area End
}
