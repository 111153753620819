// Customizable Area Start
import React, {Component} from "react";

import AddIcon from "@material-ui/icons/Add";
import {handleFormError} from "../../../../components/src/ErrorHandle"
import MoreVertIcon from "@material-ui/icons/MoreVert";

import {

    Box,
    Button,
    Typography,

    TextField,
    Grid,
    Select,
    MenuItem,
    Divider,
    Card,
    CardContent,
    FormLabel,
    CardHeader,
    IconButton,
    CardMedia

} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import {Form, Formik} from "formik";
import "../../../../web/src/assets/css/style.scss";
// import Ben from "../../assets/punjab.png" 
import {imgLogo} from "../assets"
import PropTypes from "prop-types";

import "./jurisdiction.css";
import * as yup from "yup";

type Props = {
    //   testID: string;
    handleButton: string;
    onClose: () => void;
    heading: string;
    formData: any;
    currencyList: any;
    timezoneList: any;
    measurementList: any;
    formulaList: any;
    countryList: any;
    match?: any
    formRef: any;
    detailsData: any,
    t: any
};


class GenericModal extends Component<Props> {

    handleClose = () => {
        this.props.onClose();
    };


    render() {
        const {t}: any = this.props;
        const JurisdictionAddSchema = yup.object().shape({
            jname: yup.string().required("Name is required").max(100),
            currency: yup.string().required('Currency is required'),
            timezone: yup.string().required('Timezone is required'),
            mobile: yup.string().required('Mobile length is required'),
            tax: yup.string().required(' Value added tax is required').max(100),
            zone: yup.number().min(0, "Zone multipler is required").required('Zone multipler is required').max(100),
            measurement: yup.string().required(' Unit of Measurement is required'),
            formula: yup.string().required('Measurement formula is required'),
            country: yup.string().required('Country is required')

        });

        const {formRef, detailsData} = this.props;


        let formValue = {
            jname: "",
            currency: "",
            mobile: "",
            timezone: "",
            formula: "",
            tax: "",
            zone: "",
            measurement: "",
            country: "",
            language:"",
        }
        if (detailsData != null) {
            formValue = {
                jname: detailsData.jurisdiction_name || "",
                currency: detailsData.currency || "",
                mobile: detailsData.mobile_number_length || "",
                timezone: detailsData.timezone || "",
                formula: detailsData.formula || "",
                tax: detailsData.value_added_tax || "",
                zone: detailsData.zone_multiplier || "",
                measurement: detailsData.unit_of_measurement || "",
                country: detailsData.country || "",
                language:detailsData.language || ""
            }
        }
        return (
            <div>
                <Formik
                    innerRef={formRef}
                    validateOnBlur={false}
                    initialValues={formValue}
                    validationSchema={JurisdictionAddSchema}
                    onSubmit={(values: any) => this.props.formData(values)}

                >
                    {(formikProps) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleBlur,
                            setFieldValue,
                        } = formikProps;
                        return (
                            <form onSubmit={formikProps.handleSubmit} className="modalTest">
                                <Box className="modal-content" style={{textAlign: "left", paddingRight: "0px",paddingBottom:"0px"}}>
                                    <Box className="headingData">
                                        <Typography className="mainheading">
                                            {t(this.props.heading)}
                                        </Typography>
                                        <CloseIcon className="closeicon" onClick={this.handleClose}
                                                   style={{paddingRight: "25px"}}/>
                                    </Box>
                                    <Divider/>
                                    <Form
                                        translate="yes"
                                        style={{
                                            overflowY: "scroll",
                                            overflowX: "hidden",
                                            height: "400px",
                                        }}
                                    >
                                        <Grid container spacing={2} style={{marginTop: "10px", paddingRight: "25px"}}>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Jurisdiction Name")}
                                                </FormLabel>
                                                <TextField
                                                    label={t("Jurisdiction Name")}
                                                    fullWidth
                                                    id="task-type-select1"
                                                    variant="outlined"
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    name="jname"
                                                    value={values.jname}
                                                    className="firstname"
                                                    onChange={(e) =>
                                                        setFieldValue("jname", e.target.value)
                                                    }

                                                />

                                                {handleFormError(t, errors.jname, touched.jname)}

                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Country")}
                                                </FormLabel>

                                                <Select
                                                    fullWidth
                                                    id="task-type-select1"
                                                    variant="outlined"
                                                    displayEmpty
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px"
                                                    }}
                                                    name="country"
                                                    value={values.country}
                                                    className="select-input inputForAssinged id"
                                                    onChange={(e) =>
                                                        setFieldValue("country", e.target.value)
                                                    }

                                                >
                                                    <MenuItem value="" disabled>
                                                        {t("Select Country")}
                                                    </MenuItem>
                                                    {this.props.countryList?.length &&
                                                        this.props.countryList.map((item: any) => {
                                                            return (
                                                                <MenuItem key={item} value={item.attributes.name}>
                                                                    {item.attributes.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                                {handleFormError(t, errors.country, touched.country)}
                                                {/* <Typography color="error" variant="subtitle2">{errors.assignedTo}</Typography> */}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Currency")}
                                                </FormLabel>

                                                <Select
                                                    fullWidth
                                                    label="ABC"
                                                    id="task-type-select1"
                                                    placeholder="Select hh"
                                                    variant="outlined"
                                                    displayEmpty
                                                    onBlur={handleBlur}
                                                    value={values.currency}
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    className="select-input inputForRole currencySelect generic_currency"
                                                    onChange={(e: any) =>
                                                        setFieldValue("currency", e.target.value)
                                                    }

                                                >
                                                    <MenuItem value="" disabled>
                                                        {t("Select Currency")}
                                                    </MenuItem>

                                                    {this.props.currencyList?.length &&
                                                        this.props.currencyList.map((item: any) => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.attributes.currency}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                                {handleFormError(t, errors.currency, touched.currency)}
                                                {/* <Typography color="error" variant="subtitle2">{errors.roleId}</Typography> */}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Timezone")}
                                                </FormLabel>
                                                <Select
                                                    fullWidth
                                                    id="task-type-select1"
                                                    variant="outlined"
                                                    displayEmpty
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    value={values.timezone}
                                                    className="select-input inputForAssinged timeSelect"
                                                    onChange={(e) =>
                                                        setFieldValue("timezone", e.target.value)
                                                    }
                                                    // onChange={(e:any) => this.props.formData(e.target.value,'timezone')}
                                                >
                                                    <MenuItem value="" disabled>
                                                        {t("Select Timezone")}
                                                    </MenuItem>
                                                    {this.props.timezoneList?.length &&
                                                        this.props.timezoneList.map((item: any) => {
                                                            return (
                                                                <MenuItem key={item} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                                {handleFormError(t, errors.timezone, touched.timezone)}
                                                {/* <Typography color="error" variant="subtitle2">{errors.assignedTo}</Typography> */}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Main Language")}
                                                </FormLabel>

                                                <Select
                                                    fullWidth
                                                    label="ABC"
                                                    id="task-type-select1"
                                                    placeholder="Select hh"
                                                    variant="outlined"
                                                    displayEmpty
                                                    onBlur={handleBlur}
                                                    value={values.language}
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    className="select-input inputForRole"
                                                >
                                                    <MenuItem value="" disabled>
                                                        {t("Select Language")}
                                                    </MenuItem>
                                                    <MenuItem value="english">
                                                        English
                                                    </MenuItem>
                                                    <MenuItem value="Arabic">
                                                        Arabic
                                                    </MenuItem>
                                                </Select>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Chairman & Vice Chairman Tenure")}
                                                </FormLabel>
                                                <Select
                                                    fullWidth
                                                    id="task-type-select1"
                                                    variant="outlined"
                                                    displayEmpty
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    value={""}
                                                    className="select-input inputForAssinged"

                                                >
                                                    <MenuItem value="" disabled>
                                                        {t("Select Tenure")}
                                                    </MenuItem>
                                                    <MenuItem value="3 Months">
                                                        {t("3 Months")}
                                                    </MenuItem>
                                                    <MenuItem value="6 Months">
                                                        {t("6 Months")}
                                                    </MenuItem>
                                                    <MenuItem value="1 Year">
                                                        {t("1 Year")}
                                                    </MenuItem>
                                                </Select>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Unit of Measurement")}
                                                </FormLabel>
                                                <Select
                                                    fullWidth
                                                    id="task-type-select1"
                                                    variant="outlined"
                                                    displayEmpty
                                                    value={values.measurement}
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    className="select-input inputForAssinged unitSelect"
                                                    onChange={(e: any) =>
                                                        setFieldValue("measurement", e.target.value)
                                                    }
                                                    // onChange={(e:any) => this.props.formData(e.target.value,'measurement')}
                                                >
                                                    <MenuItem value="" disabled>
                                                        {t("Select Unit of Measurement")}
                                                    </MenuItem>
                                                    <MenuItem value={0}>
                                                        {t("Sq ft")}
                                                    </MenuItem>
                                                    <MenuItem value={1}>
                                                        {t("Sq m")}
                                                    </MenuItem>
                                                </Select>
                                                {handleFormError(t, errors.measurement, touched.measurement)}
                                                {/* <Typography color="error" variant="subtitle2">{errors.roleId}</Typography> */}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Mobile number length")}
                                                </FormLabel>
                                                <Select
                                                    fullWidth
                                                    id="task-type-select1"
                                                    variant="outlined"
                                                    displayEmpty
                                                    value={values.mobile}
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    className="select-input inputForAssinged mobileSelect"
                                                    onChange={(e: any) =>
                                                        setFieldValue("mobile", e.target.value)
                                                    }
                                                >
                                                    <MenuItem value="" disabled>
                                                        {t("Select Mobile Number Length")}
                                                    </MenuItem>
                                                    <MenuItem value={9}>9</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={11}>11</MenuItem>
                                                </Select>
                                                {handleFormError(t, errors.mobile, touched.mobile)}
                                                {/* <Typography color="error" variant="subtitle2">{errors.assignedTo}</Typography> */}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Value Added Tax")}
                                                </FormLabel>
                                                <TextField
                                                    label={t("Value added tax")}
                                                    fullWidth
                                                    id="task-type-select1"
                                                    variant="outlined"
                                                    type="number"
                                                    className="taxSelect"
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    value={values.tax}
                                                    onChange={(e: any) =>
                                                        setFieldValue("tax", e.target.value)
                                                    }
                                                />
                                                {handleFormError(t, errors.tax, touched.tax)}
                                                {/* <Typography color="error" variant="subtitle2">{errors.roleId}</Typography> */}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Zone Multipler")}
                                                </FormLabel>
                                                <TextField
                                                    label={t("Zone multipler")}
                                                    type="number"
                                                    fullWidth
                                                    id="task-type-select1"
                                                    variant="outlined"
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    value={values.zone}
                                                    className="zoneSelect"
                                                    onChange={(e: any) =>
                                                        setFieldValue("zone", e.target.value)
                                                    }
                                                />
                                                {handleFormError(t, errors.zone, touched.zone)}
                                                {/* <Typography color="error" variant="subtitle2">{errors.assignedTo}</Typography> */}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel
                                                    component="legend"
                                                    className="addTeamModal jur-text"
                                                    style={genericCSS.labelsStyle}
                                                >
                                                    {t("Management Fee Calculation Formula")}
                                                </FormLabel>
                                                <Select
                                                    fullWidth
                                                    label="ABC"
                                                    id="task-type-select1"
                                                    placeholder="Select hh"
                                                    variant="outlined"
                                                    displayEmpty
                                                    value={values.formula}
                                                    style={{
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#f9f9f9",
                                                        marginRight: "10px",
                                                    }}
                                                    className="select-input inputForRole formulaSelect"
                                                    // defaultValue="Select"
                                                    onChange={(e: any) =>
                                                        setFieldValue("formula", e.target.value)
                                                    }
                                                    // onChange={(e:any) => this.props.formData(e.target.value,'formula')}
                                                >
                                                    <MenuItem value="" disabled>
                                                        {t("Select Formula")}
                                                    </MenuItem>
                                                    <MenuItem value={0} >
                                                        {t("(TS + QT + IM) MS")}
                                                    </MenuItem>
                                                    <MenuItem value={1} >
                                                        {t("Unit Size * Zone Multiplier")}
                                                    </MenuItem>
                                                </Select>
                                                {handleFormError(t, errors.formula, touched.formula)}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <FormLabel
                                                        component="legend"
                                                        className="addTeamModal jur-text"
                                                        style={genericCSS.default}
                                                    >
                                                        {t("Default Renting Contract")}
                                                    </FormLabel>
                                                    <FormLabel
                                                        component="legend"
                                                        className="addTeamModal jur-text bold"
                                                        style={{color: "#FC8434", fontSize: "16px", fontWeight: 800}}
                                                    >
                                                        +ADD NEW TEMPLATE
                                                    </FormLabel>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{display: "flex"}}>
                                                    <Card className="card-info" style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        position: "relative"
                                                    }}>
                                                        <CardHeader

                                                            style={{
                                                                padding: "5px",
                                                                position: "absolute",
                                                                right: "0",
                                                                zIndex: 1000
                                                            }}
                                                            action={
                                                                <IconButton aria-label="settings">
                                                                    <MoreVertIcon/>
                                                                </IconButton>
                                                            }

                                                        />
                                                        <CardMedia
                                                            component="img"
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                zIndex: 100,
                                                                transform: "translateY(-50%)"
                                                            }}
                                                            image={imgLogo}
                                                            alt="Paella dish"
                                                        />
                                                        <CardContent style={{
                                                            zIndex: 1000,
                                                            position: "absolute",
                                                            bottom: "5px",
                                                            padding: "0px",
                                                            textAlign: "center",
                                                            width: "100%"
                                                        }}>
                                                            <Typography>ABCC</Typography>
                                                        </CardContent>
                                                    </Card>

                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                    <div className="adjustBtn ">

                                        <Button onClick={this.handleClose} className="btn-cancel bold-text ">
                                            {t("CANCEL")}
                                        </Button>
                                        <Button className="btn-add " onClick={() => formikProps.handleSubmit()}>
                                            {t(this.props.handleButton)}
                                        </Button>
                                    </div>
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

const genericCSS = {


    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },


    labelsStyle: {
        color: "#212121",
        margin: "10px 0px 10px 0px",
        fontSize: "14px"
    },
    default: {
        color: "#212121",
    }


};
export default GenericModal;
// Customizable Area End
