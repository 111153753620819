//Customizable Area Start
import React,{CSSProperties} from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Container,
  Select,
  Menu,
  MenuItem,
  Divider,
  InputBase,
  Modal,
  withStyles,
  TextField,
  IconButton,
  Tooltip,
  
} from "@material-ui/core";
import { withRouter ,Link} from "react-router-dom";

import JurisdictionFeedController, {
  Props,
} from "./JurisdictionFeedController.web";
import GenericModal from "./GenericModal.web";
import "./jurisdiction.css";
import "@szhsin/react-menu/dist/core.css";

import MoreVertIcon from "@material-ui/icons/MoreVert";


import SearchIcon from "@material-ui/icons/Search";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import { withTranslation } from "react-i18next";
//@ts-ignore
import { ReportsStyleWeb } from "../../../StoreCredits/src/ReportsStyle.web";
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";


import Loader from "../../../../components/src/Loader.web";

 export class JurisdictionFeed extends JurisdictionFeedController {
   
  

  handleClose = () => {
    this.setState({ genericModalOpen: false });
  };
  

  handleMenuClose = () => {
    this.setState({ isMenu: null });
  };

 
  handleAddJurisdiction = () => {
    this.setState({ genericModalOpen: true });
  };
  handleView = () => {
    this.props.history.push("/CompanyEmployee/ViewJurisdiction")
   
  };
  handleTableCell=(item: any, key: any,t:any)=>{
    return(
      <>
      <TableRow>
        <TableCell className="tablecell">
          {key+1}
        </TableCell>
        <TableCell className="tablecell">
          <Tooltip title={item && item.attributes &&item.attributes.jurisdiction_name}>
            <div>
            {(item && item.attributes &&item.attributes.jurisdiction_name.length<=10)?(item && item.attributes &&item.attributes.jurisdiction_name) :(item && item.attributes &&item.attributes.jurisdiction_name.substring(0, 10).concat(".."))}
          
            </div>
          </Tooltip>
         
        </TableCell>
        <TableCell className="tablecell">
          {item && item.attributes &&item.attributes.jurisdiction_id}
        </TableCell>
        <TableCell className="tablecell">
          {item && item.attributes &&item.attributes.country}
        </TableCell>
        <TableCell className="tablecell">
          {item && item.attributes &&item.attributes.total_complex}
        </TableCell>
        <TableCell className="tablecell">
          {item && item.attributes &&item.attributes.total_buildings}
        </TableCell>
        <TableCell className="tablecell">
          {item && item.attributes &&item.attributes.currency?.currency}
        </TableCell>
        <TableCell className="tablecell" style={{ textOverflow: "ellipsis" }}>
          <Tooltip   title={item.attributes &&item.attributes.timezone}>
            <Box>{(item && item.attributes.timezone.length<=10)?(item.attributes.timezone) :(item && item.attributes &&item.attributes.timezone.substring(0, 10).concat(".."))}</Box>
          
          </Tooltip>
         
        </TableCell>
        <TableCell className="tablecell" style={webStyle.moreOptions}>
          <MoreVertIcon
            style={webStyle.moreIcon}
            onClick={() =>
              this.handleDetailsOptionsData(item.id)
            }
            id="selectmore"
          />
         
          {this.state.openDetailsView === item.id && (
            <Box style={webStyle.rowOptions}>
              <ul style={webStyle.rowList}>
                <li >
                  <Link
                    to={`/CompanyEmployee/ViewJurisdiction/${item.id}`}
                  
                  >
                    {t("View Details")}
                  </Link>
                </li>
              </ul>
            </Box>
          )}
        </TableCell>
        {/* <TableCell className="tablecell">
          <MoreVertIcon
            onClick={(e) => this.handleMore(e)}
          />
        </TableCell> */}
      </TableRow>
    </>
    )
  }

  handleViewDetails = (t:any) => (
    <>
      <Menu
        id="simple-menu"
        anchorEl={this.state.isMenu}
        keepMounted
        open={this.state.isMenu != null ? true : false}
        onClose={this.handleMenuClose}
        className="menu-field"
      >
        <MenuItem className="menu-field" onClick={this.handleView}>
          {t("View Details")}
        </MenuItem>
      </Menu>
    </>
  );
 
  handleModalClose = () => {
    this.setState({ genericModalOpen: false });
  };
//  handleSearchCountry=()=>{
//   e.preventDefault()
// // this.setState({searchCountry:e.target.value})
// this.filterByCountry()
//  }
 handleDetailsOptionsData = (id: number) => {
  
  this.setState({ openDetailsView: id });
};

  render() {
    const { t, classes }: any = this.props;

    return (
      <MainScreen>
        {this.handleViewDetails(t)}
        <Modal
          open={this.state.genericModalOpen}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-backdrop"
        >
          <GenericModal
            handleButton={this.state.isEditMode ? "SAVE" : "ADD"}
            onClose={this.handleModalClose}
            currencyList={this.state.currencyList}
            timezoneList={this.state.timezoneList}
            measurementList={this.state.measurementList}
            formulaList={this.state.formulaList}
            countryList={this.state.countryList}
            detailsData={<></>}
            formRef={this.formRef}
           
            heading={
              this.state.isEditMode
                ? "Edit New Jurisdiction"
                : "Add New Jurisdiction"
            }
            t={t}
            //@ts-ignore
            formData={(values)=>{this.addJurisidiction(values)}}
           
          />
        </Modal>
        
        <Box style={{ background: "#F4F7FF" }} className={classes.reportList}>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            style={{ paddingTop: 35, minHeight: "95vh" }}
          >
            <Container>
              <Box className="navigation">
                <Box>
                  <Typography variant="body1">
                    <Box
                      className="reports-BudgetReportmain budgetReportBackButton"
                      component="span"
                      onClick={() => this.props.history.push("/Reports")}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t(" My Dashboards")}
                    </Box>{" "}
                    /{" "}
                    <Box
                      className="reports-BudgetReportmain"
                      component="span"
                      style={{ color: "#2B6FED" }}
                    >
                     {t("Manage Jurisdiction")}
                    </Box>
                  </Typography>

                  <Typography
                    variant="h5"
                    className="sub-heading bold-text reports-BudgetReportmain"
                    style={{ fontSize: "32px" }}
                  >
                    {t("Manage Jurisdiction")}
                  </Typography>
                </Box>
              </Box>
              <Box className="top-bar">
                <Box className="filter">
                  <Select
                    displayEmpty
                    className="select-input budgetReportStatusListSelect"
                    value={this.state.searchCountry}
                    onChange={(e)=>this.setState({searchCountry:e.target.value})}
                    id="countrySearch"
                  >
                    <MenuItem value="" disabled id="selectStatusBudgetReport ">
                      {t("Country")}
                    </MenuItem>
                    <MenuItem value=" " id="selectStatusBudgetReportAll">
                      {t("All")}
                    </MenuItem>
                   
                    {this.state.countryList.length && this.state.countryList.map((item:any)=>{
                      return(
                          <MenuItem key={item} value={item}>{item}</MenuItem>
                           )
                    })}
                  </Select>
                  <Button
                    startIcon={<SearchIcon />}
                    className="budgetReportSearchButton"
                    onClick={this.handleFilterSubmit}
                    id="searchCountry"
                    // onClick={() => this.getBudgetReport(this.state.status, this.state.budgetYear, this.state.searchName, 1)}
                  >
                    {t("Search")}
                  </Button>
                </Box>

                <Box className="create-meeting newJurisdiction">
                  <Button
                    onClick={() => {
                      this.setState({ genericModalOpen: true });
                    }}
                    id="addJurisdiction"
                    className="bold-text createNewAnnouncment"
                  >
                    {t("Add New Jurisdiction")}
                  </Button>
                </Box>
              </Box>
              <Grid
                className="meeting-table"
                style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
              >
                <Grid item sm={12} md={12} xs={12}>
                  <Box className="table-top">
                    <h3 className="bold-text reports-BudgetReportmain " style={{fontSize:"22px"}}>
                     {t("List of Jurisidiction")}
                    </h3>
                    <div >
                    <TextField
                        label="Search"
                        variant="outlined"
                        className="table_search"
                        style={webStyle.searchInput}
                        placeholder={`${t("Search by ID")}`}
                        onKeyUp={this.handleSearchKeyUp}
                        InputProps={{
                            startAdornment: (
                                <IconButton 
                                style={webStyle.searchIconButton}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                            style: webStyle.innerInput
                        }}
                    />
                     
                    </div>
                  </Box>
                  <Divider />
                  <Table className="table-box">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="bold-text reports-BudgetReportmain1"
                          style={webStyle.tableColor}
                         
                        >
                          {t("#")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainYear"
                          style={webStyle.tableColor}
                        >
                          {t("Jurisdiction Name")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainGenerated jid"
                          style={webStyle.tableColor}
                        >
                          {t("Jurisdiction ID")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount cn"
                          style={webStyle.tableColor}
                        >
                          {t("Country")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount tc"
                          style={webStyle.tableColor}
                        >
                          {t("Total Complexes")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount tb"
                          style={webStyle.tableColor}
                        >
                          {t("Total Buildings")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount cuid"
                          style={webStyle.tableColor}
                        >
                          {t("Currency")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetStatus time"
                          style={webStyle.tableColor}
                        >
                          {t("Timezone")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount"
                          style={webStyle.tableColor}
                        ></TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.jurisdictionList.length > 0 ? (
                        this.state.jurisdictionList.map(
                          (item: any, key: any) => {
                            return (
                              <>
                             {this.handleTableCell(item, key,t)}
                             </>
                            );
                          }
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            {t("No Jurisdiction Reports Available")}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Divider />
                  <Box className="table-bottom">
                    <PaginationModule
                      pagination={this.state.pagination}
                      handlePagination={this.handleJurisdictionPagination}
                      page={this.state.page}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Loader loading={this.state.loading} />
        </Box>
        {/* <ViewJurisdiction navigation={navigation} viewId={id} id={id}/> */}
      </MainScreen>
    );
  }
}
const webStyle = {
  moreOptions: {
    position: "relative" as CSSProperties["position"],
  },
  moreIcon: {
    color: "rgba(136,136,136,0.6)",
    cursor: "pointer",
  },
  rowOptions: {
    position: "absolute" as CSSProperties["position"],
    background: "#fff",
    width: "fit-content",
    left: "-80px",
    boxShadow: "0px 0px 6px rgba(87,148,201,0.18)",
    zIndex: "9999999" as CSSProperties["zIndex"],
    padding: "15px",
  },
  searchIconButton:{
    padding: '0px'
  },
  searchInput:{
    border:'1px solid rgba(0,0,0,0.2)',
    borderRadius:'8px',
    height:'40px',
    padding:'0px',
    color:'#212121'
},
  
  rowList: {
    listStyle: "none",
  },
  tableColor:{
    color: "#181d25", 
    fontSize: "14px" ,
    textAlign:"left" as CSSProperties['textAlign']
  },
  innerInput:{
    padding:'0px',
    color:'#212121'
}
};
export default withTranslation()(withStyles(ReportsStyleWeb )(withRouter(JurisdictionFeed))
);

//Customizable Area End
