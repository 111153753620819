// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import PropTypes from "prop-types";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import {  FormikProps} from "formik";
import React from "react"


export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  location: any;
  match: any;
  t:any;
  
}

interface S {
  loading: boolean;
  addBuildingOpen:boolean;
  isDetailsMenu:null | HTMLElement;
  isMenuSelected:null | HTMLElement;
  confirmModal:boolean,
  changejurisdictionModal:boolean;
  modalTest:boolean;
  editModal:boolean;
  country:any;
  region:any;
  status:any;
  city:any;
  accountManager:any;
  countryListdata:any;
  selectedCountry:any;
  cityList:any;
  detailsData:any;
  statusList:any;
  amList:any;
  complexList:any;
  page:any;
  pagination:any;
  regionList:any;
  companyName:any;
  currencyList:any,
  timezoneList:any,
   measurementList:any,
   viewLocationstate:boolean,
   complexLan:number,
   complexLong:number,
  formulaList:any,
  complex:any;
    jurisdictionList:any;
    newJur:any;
    newDetailsData:any;
    selectedComplex:any;
    complex_countryselected:any;
    complex_cityselected:any;
    complex_complexSelected:any;
    deleteId:any;
    complexBuildingList:any
    showError:boolean,
    error:any;
    complexSearchquery:any;
    countryDisplay:any;
    changeId:any;
    openOptions:number
    switchId:any,
    tabNo:number,
    newJurId:any,
    complexById:any;
    oldId:any;
   
 
 
}
interface FilledDataTypes{
  jname: string,
  jid: number,
  currency: string,
  mobile: number,
  timezone: string,
  formula: string,
  tax: string,
  zone: string,
  measurement: string,
  
}
interface SS {
  id: any;
}

  export default class ViewJurisdictionController extends CommonApiCallForBlockComponent<Props, S, SS> {
  exampleAPICallId: string = "";
  getCountryCallId:any;
  getJurisdictionApiCallId:string="";
  getCitiesApiCallId :any;
  getStatusDataApiCallId:any;
  getAmDataApiCallId:any;
  getComplexApiCallId:any;
  // getRegionApiCallId:any;
  GetFilterApi:any;
  getCurrencyApiCallId:any;
  getTimezoneApiCallId:any;
  getMeasurementApiCallId:any;
  getformulaApiCallId:any;
  getJurisidictionList:any;
  getNewJurisdictionApiCallId:any
  deleteJurisidictionApiCallId:any;
  getBuildingApiCallId:any;
  getSelectedApiCallId:any;
  getComplexFilterApiCallId:any;
  editJurisdictionCallId:any;
  changeJurisdictionApiCallId:any;
  complexChangeJurisdictionApi:any;
  getComplexByIdApiCallId:any;
  formRef: React.RefObject<FormikProps<FilledDataTypes>>

  static propTypes={
    genericModalOpen:PropTypes.bool,
  }
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
   this.formRef = React.createRef();
   

    this.state = {
      loading: false,
      addBuildingOpen:false,
      isDetailsMenu:null,
      isMenuSelected:null,
      confirmModal:false,
      changejurisdictionModal:false,
      modalTest:false,
      editModal:false,
      viewLocationstate:false,
      complexLan:0,
      complexLong:0,
      country:"",
      city:"",
      region:"",
      accountManager:"",
      status:"",
      countryListdata:[],
      selectedCountry:"",
      cityList:[],
      detailsData:null,
      statusList:[],
      amList:[],
      complexList:[],
      regionList:[],
      page:1,
      pagination: {
        current_page: 1,
        total_count: 0,
        total_pages: 1,
      },
      companyName:"",
      currencyList:[],
      timezoneList:[],
      measurementList:[],
      formulaList:[],
      complex:"",
      jurisdictionList:[],
      newJur:"",
      newDetailsData:null,
      selectedComplex:"",
      complex_countryselected:"",
      complex_cityselected:"",
      complex_complexSelected:"",
      deleteId:"",
      complexBuildingList:"",
      showError:false,
      error:null,
      complexSearchquery:"",
      countryDisplay:"",
      changeId:"",
      openOptions:0,
      switchId:"",
      tabNo:0,
      newJurId:"",
      complexById:"",
      oldId:""
      
     
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    //call the method
   
    this.getCountryListdata();
    this.getJurisdictionById();
    // this.getCitiesList()
    this.getStatusList()
    this.getAmList()
    this.getComplexList()
    // this.getRegionList()
    this.getCurrencyListData();
    this.getTimezoneListData();
    this.getMeasurementListData();
    this.getFormulaListData();
    this.getJurisdiction()
  }

  formVal=(values:any)=>{
  
    const formData = new FormData();
    formData.append("jurisdiction[jurisdiction_name]", values.jname);
    formData.append("jurisdiction[currency]", values.currency);
    formData.append("jurisdiction[timezone]", values.timezone);
    formData.append("jurisdiction[unit_of_measurement]", values.measurement);
    formData.append("jurisdiction[mobile_number_length]", values.mobile);
    formData.append("jurisdiction[formula]", values.formula);
    formData.append("jurisdiction[zone_multiplier]", values.zone);
    formData.append("jurisdiction[value_added_tax]", values.tax);
    formData.append("jurisdiction[country]",values.country)
    
    return formData
   
  }
  editJurisdiction=async(values:any)=>{
    const match:any= this.props.match;
    let id = match?.params?.id;
   const val= this.formVal(values)
    this.editJurisdictionCallId = await apiCall({  
      method: "PATCH",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
      body:val
    });

  }
  handleFilterSubmit = (event:any) => {
    event?.preventDefault()
    
    this.getComplexList()
  }
  handleTabChange = (event: any, newValue: number) => {
    this.setState({tabNo: newValue})
  }
  handlePagination = (e: any, value: any) => {
    this.setState({
      page:value
    })
   
   
  };
  showError = () => {
    if (this.state.error) {
        this.setState({
            showError: true,
            loading:false
        })
    }
}
  getJurisdiction = async () => {
    this.getJurisidictionList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions`,
    });
  };
  getCurrencyListData=async()=>{
    this.getCurrencyApiCallId=await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/list_of_currency`,
    })
  }
  getTimezoneListData=async()=>{
    this.getTimezoneApiCallId=await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/list_of_timezone`,
    })
  }
  getMeasurementListData=async()=>{
    this.getMeasurementApiCallId=await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/list_of_measurement`,
    })
  }
  getFormulaListData=async()=>{
    this.getformulaApiCallId=await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/list_of_formula`,
    })
  }
  getSearchFilter = async () => {
    this.setState({loading: true})
    this.GetFilterApi = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/filter?complex_name=${this.state.companyName}
      &country=${this.state.country.trim()}&city=${this.state.city.trim()}&region=${this.state.region.trim()}
      &status=${this.state.status.trim()}`,
    });
  };
  getCountryListdata=async()=>{
    this.getCountryCallId=await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/addresses`,
    })
  }
  handleMapvalues=(item:any)=>{
  this.setState({
    complexLan:item && item.attributes && item.attributes.lat,
    complexLong:item && item.attributes &&item.attributes.long
  })
  }

  getCitiesList = async (val:any) => {
  
    this.getCitiesApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_real_estate_companies/region_city_by_country?country=${val}`,
    });
  };
  
  deleteJurisidiction=async()=>{
    const match:any= this.props.match;
    let id = match?.params?.id;

    this.deleteJurisidictionApiCallId=await apiCall({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
    })
  }
  getJurisdictionById=async()=>{
   
     const match:any= this.props.match;
    let id = match?.params?.id;
    
    
   
  
    this.getJurisdictionApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
    });
  }
  changeJurisdictionById=async(id:any)=>{
   
   this.changeJurisdictionApiCallId = await apiCall({
     contentType: "application/json",
     method: "GET",
     endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
   });
 }
  getNewJurisdictionById=async(id:any)=>{
   
    
  
 
   this.getNewJurisdictionApiCallId = await apiCall({
     contentType: "application/json",
     method: "GET",
     endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
   });
 }
  getStatusList = async () => {
    this.getStatusDataApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/status`,
    });
  };
  getAmList = async () => {
    this.getAmDataApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/account_manager`,
    });
  };
  getComplexList = async () => {
    const match:any= this.props.match;
    let id = match?.params?.id;
    this.setState({loading:true})
    this.getComplexApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complex_list?page=${this.state.page}&per_page=10&jurisdiction_id=${id}&complex=${this.state.complexSearchquery}&account_manager=${this.state.accountManager}
      &country=${this.state.country.trim()}&city=${this.state.city.trim()}&region=${this.state.region.trim()}
      &status=${this.state.status.trim()}`,
    });
  };
  getComplexFilter = async () => {
    this.setState({loading:true})
    this.getComplexFilterApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/search_by_complex?complex_name=${this.state.complexSearchquery}`,
    });
  };
  getComplexDetails= async () => {
   console.log("CALLED1")
    this.setState({loading:true})
    this.getComplexByIdApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/${this.state.switchId}`,
    });
  };
  complexChangeJurisdiction=async()=>{
   

    this.complexChangeJurisdictionApi = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `bx_block_society_management/company_employee/jurisdiction_change?jurisdiction_id=${this.state.newJurId}&society_management_id=${this.state.switchId}&id=${this.state.oldId}`,
     
    });
  }
  handleAddBuilding=async()=>{
    this.setState({loading:true})
    const formData = new FormData();
    formData.append("country", this.state.complex_countryselected);
    formData.append("city", this.state.complex_cityselected);
    formData.append("society_management_id", this.state.complex_complexSelected);
  
    this.getBuildingApiCallId = await apiCall({
      body:formData,
      method: "POST",
      endPoint: `bx_block_society_management/company_employee/add_individual_complex_building`,
    });
  }
  handleSelectComplex=async()=>{
    this.getSelectedApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_custom_form/incident_managements/list_of_complex?country=${this.state.complex_countryselected}&city=${this.state.complex_cityselected}`,
    });
  }
  handleCountry=(responseJson:any)=>{
    this.setState({countryListdata:responseJson.data})
   }
   handleDataById=(response:any)=>{
   if(response){
    this.setState({detailsData:response?.data?.attributes,countryDisplay:response?.data?.attributes?.jurisdiction_name,oldId:response.data?.id})
   }
   
   }
   changeJurisdictionData=(response:any)=>{
    if(response){
     
      this.setState({detailsData:response?.data?.attributes,countryDisplay:response?.data?.attributes?.jurisdiction_name})
     
      this.props.history.push(`/CompanyEmployee/ViewJurisdiction/${this.state.changeId}`)
     }
   }
   handleCitiyListSucc=(resposeJson:any)=>{
   
    this.setState({cityList:resposeJson, regionList:resposeJson})
  }
  handleStatus=(response:any)=>{
    
    this.setState({statusList:response.status})
  }
  handleAmList=(response:any)=>{
    this.setState({amList:response.account_manager_role})
  }
  handleComplexList=(response:any)=>{
    this.setState({ loading:false,})
    if(response){
      this.setState({complexList:response?.complexes?.data,
       
        pagination:{
          current_page:response.meta?.pagination?.current_page,
          total_count:response.meta?.pagination?.total_count,
          total_pages:response.meta?.pagination?.total_pages,
        },
       
        })
    }
    
  }
 
  handleFilterData=(responseJson:any)=>{
   
    if(responseJson){
      this.setState({ complexList: responseJson.complexes.data,
        pagination:{
          current_page:responseJson.meta.pagination?.current_page,
          total_count:responseJson.meta.pagination?.total_count,
          total_pages:responseJson.meta.pagination?.total_pages,
        },
       

      })
      // this.setState({complexList:response.complexes.data,loading:false,page:response.meta?.pagination?.current_page, pagination:response.meta?.pagination})
    }
   
  }
  handleCurrencyListSucc=(responseJson:any)=>{
    this.setState({currencyList:responseJson.currency_list})
  }
  handleTimeZoneSucc=(responseJson:any)=>{
    this.setState({timezoneList:responseJson.timezones})
  }
  handleSearchKeyUp = (e:any) => {
  

    const searchQuery = e.target.value;
      this.setState({complexSearchquery: searchQuery }, () => {
      
        this.getComplexList()
      });
      
   
  };
  handleMeasurementSucc=(responseJson:any)=>{
    this.setState({measurementList:responseJson.list_of_measurement})
  }
  handleFormulaSucc=(responseJson:any)=>{
  
    this.setState({formulaList:responseJson.list_of_formula})
   }
   handleJurisdictionListSucc = (resposeJson: any) => {
   
    if (resposeJson.jurisdictions.data?.length) {
      this.setState({ jurisdictionList: resposeJson.jurisdictions.data ,
       });
    }
  
  };
  getNewJurisdiction=(response:any)=>{
    this.setState({newDetailsData:response?.data?.attributes})
  }
  handleSelectComplexList=(responseJson:any)=>{
    this.setState({complexBuildingList:responseJson.data})
  }
  handleBuildingAdd=(response:any)=>{
    if(response.data){
      this.setState({addBuildingOpen:false,loading:false,complex_cityselected:"",complex_countryselected:"",complex_complexSelected:""})
    }
    
  }
  getComplexFilterList=(responseJson:any)=>{
    if (responseJson.complexes.data) {
      this.setState({ complexList: responseJson.complexes.data,
        pagination:{
          current_page:responseJson.meta?.pagination?.current_page,
          total_count:responseJson.meta?.pagination?.total_count,
          total_pages:responseJson.meta?.pagination?.total_pages,
        },
      })
    }
     
  }
  getEditJurisdiction=(responseJson:any)=>{
    this.setState({detailsData:responseJson?.data?.attributes,editModal:false})
  }
  deleteJurisdictionSucc=(responseJson:any)=>{
    if (responseJson.code) {
      this.setState({confirmModal: false})
      setTimeout(() => {
        this.props.navigation?.navigate('JurisdictionFeed')
      }, 2000)
    }else{
      this.setState({error:responseJson.message})
        this.showError()
    }
   this.setState({changejurisdictionModal:false})
    
  }
  handlecomplexJurisdictionChange=(responseJson:any)=>{
    this.setState({changejurisdictionModal:false})
    if(responseJson?.jurisdiction){
      this.setState({newJurId:""})
      this.props.navigation.history.push("/CompanyEmployee/jurisdication")
     
    }
   
    
  }
  handleViewlocation=()=>{
    this.setState({
      viewLocationstate:!this.state.viewLocationstate
    })
  }
  handleComplexDetailsById=(responseJson:any)=>{
   
    if(responseJson?.data)
    {
      this.setState({complexById:responseJson.data.attributes.name, })
    }
    
  }
  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      await this.getComplexList();
    }
  }
  async receive(from: string, message: Message) {
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (responseJson) {
        
        switch (apiRequestCallId) {
         
          case this.getJurisidictionList:
            return this.handleJurisdictionListSucc(responseJson);
          case this.getCountryCallId:
            return this.handleCountry(responseJson)
          case this.getJurisdictionApiCallId:
            return this.handleDataById(responseJson)
            case this.getCitiesApiCallId:
              return this.handleCitiyListSucc(responseJson)
            case this.getStatusDataApiCallId:
            return this.handleStatus(responseJson)
            case this.getAmDataApiCallId:
              return this.handleAmList(responseJson)
            case this.getComplexApiCallId:
              return this.handleComplexList(responseJson)
              case this.GetFilterApi:
               return this.handleFilterData(responseJson)
              case this.getCurrencyApiCallId:
                return this.handleCurrencyListSucc(responseJson)
              case this.getTimezoneApiCallId:
                return this.handleTimeZoneSucc(responseJson)
              case this.getMeasurementApiCallId:
                return this.handleMeasurementSucc(responseJson)
              case this.getformulaApiCallId:
                return this.handleFormulaSucc(responseJson)
              case this.getNewJurisdictionApiCallId:
                return this.getNewJurisdiction(responseJson)
              case this.deleteJurisidictionApiCallId:
                return this.deleteJurisdictionSucc(responseJson)
              case this.getSelectedApiCallId:
                return this.handleSelectComplexList(responseJson)
              case this.getBuildingApiCallId:
                return this.handleBuildingAdd(responseJson)
              case this.getComplexFilterApiCallId:
                return this.getComplexFilterList(responseJson)
              case this.editJurisdictionCallId:
                return this.getEditJurisdiction(responseJson)
              case this.changeJurisdictionApiCallId:
                return this.changeJurisdictionData(responseJson)
              case this.complexChangeJurisdictionApi:
                return this.handlecomplexJurisdictionChange(responseJson)
              case this.getComplexByIdApiCallId:
                return this.handleComplexDetailsById(responseJson)
             
        }
      } 
    }
    //Api call
  }
  

}
// export default ViewJurisdictionController
// Customizable Area End
