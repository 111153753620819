import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import { CEApiCall } from "../../../components/src/APICallComponent/index.web";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    t: (val: string) => string;
    i18n: {
        language: string;
    };
    match?: any;
    location?: any;
    history?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    page: string;
    sortBy: string;
    searchText: string;
    salesManagerId: string;

    salesManagerDataSave: any[];
    paginationData: any | null;

    showError: boolean;
    showErrorMessage: string;
    loader: boolean;
    anchorEl: HTMLButtonElement | null;
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SalesManagerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    salesManagerListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ContractDetailsId),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            salesManagerDataSave: [],
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0,
            },

            salesManagerId: "",
            sortBy: "",
            searchText: "",
            page: "",

            showError: false,
            showErrorMessage: "",
            loader: false,
            anchorEl: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (responseJson && !responseJson.errors) {
                this.salesManagerApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.salesManagerApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        this.getAssignedSalesManagerList("1");
    }

    salesManagerApiResponseSucessCell = async (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.salesManagerListApiCallId) {
            this.salesManagerListSucessCallBack(responseJson);
        }
    };

    salesManagerApiResponseFailureCall = async (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.salesManagerListApiCallId) {
            this.salesManagerListFailureCallBack(responseJson);
        }
    };

    getAssignedSalesManagerList = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.assignedSalesManagerEndPoint}page=${page}`;
        let { salesManagerId, searchText, sortBy } = this.state;
        if (salesManagerId) {
            endPoint += `&sales_member_id=${salesManagerId}`;
        }
        if (searchText) {
            endPoint += `&q=${searchText}`;
        }
        if (sortBy) {
            endPoint += `&sort_by=${sortBy}`;
        }
        this.salesManagerListApiCallId = await CEApiCall({
            method: "GET",
            contentType: "application/json",
            endPoint: endPoint,
        });
    };

    salesManagerListSucessCallBack = (response: any) => {
        if (response.meta.pagination !== null) {
            this.setState({ salesManagerDataSave: response.data, paginationData: response.meta.pagination, loader: false });
        } else {
            this.setState({
                salesManagerDataSave: response.data,
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0,
                },
                loader: false,
            });
        }
    };

    salesManagerListFailureCallBack = (response: any) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    handleSalesManagerChange = (event: ChangeEvent<{ value: unknown }>) => {
        this.setState({ salesManagerId: event.target.value + "" });
    };

    handleSortByChange = (event: ChangeEvent<{ value: unknown }>) => {
        this.setState({ sortBy: event.target.value as string }, () => {
            this.getAssignedSalesManagerList("1");
        });
    };

    handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value }, () => {
            this.getAssignedSalesManagerList("1");
        });
    };

    handleNavigation = (salesManagerId: string) => {
        setStorageData("viewProfileid",salesManagerId)
        setStorageData("profileRole","Sales Manager")
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          message.addData(getName(MessageEnum.NavigationTargetMessage), "SalesManagerProfileDashboard");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          const raiseMessage = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
          );
          raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
            id: salesManagerId
          });
          message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
          this.send(message);
    };

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.getAssignedSalesManagerList(String(value));
    };

    handleAlertError = () => {
        this.setState({ showError: false });
    };

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
      handleClose = () => {
          this.setState({ anchorEl: null });
      };
    // Customizable Area End
}
